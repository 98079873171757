@import "~react-image-gallery/styles/css/image-gallery.css";
.stories
{
    margin-top: 80px;
    margin-bottom: 80px;
    background-image: url("../../assets/album/bg5.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    
}
.story
{
    display: block;
    margin:0px;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 90vh;
    width:65.6vh;
    object-fit: cover;
    border:20px solid #1c0e00;
    box-shadow: #1c0e00 0px 50px 500px ;
}
.story2
{
    display: block;
    margin:0px;
    margin-top: 20px;
    margin-bottom: 20px;
    width:80vw;
    object-fit: cover;
    border:10px solid #1c0e00;
    box-shadow: #1c0e00 0px 50px 500px ;
}
.art
{
    color: #482400;
    padding: 10px;
  margin-bottom: 0px;
  background-image: linear-gradient(#fff0,#ffffff,#fff0);
  background-position: center;
  background-size: cover;
    font-family: "Monteserrat";
    font-weight: 600;
 
}
.thick-line
{
    background-image: linear-gradient(90deg,#fff,#1c0e00,#fff);
    height: 0px;
}
.shadow
{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}