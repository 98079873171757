
.sec00
{
    background-image: linear-gradient(#1870B0,#fff);
    border-radius: 20px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5)
}
.secaa
{
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5)
}
.btn1
{
    background: #E9BD3A;
    width:100%;
    margin: 0   ;
    border: none;
    padding: 10px;
    color: black;
    font-weight: bold;
}
.btn2
{
    background: #D9D9D9;
    width: 100%;
    border: none;
    padding: 10px;
    color: black;
    font-weight: bold;
}
.selected
{
    border-top: 1px solid #EDCE72;
    border-bottom: 1px solid #EDCE72;
}
.dot
{
    background-color: red;
    width:10px;
    height: 10px;
    border-radius: 100%;
    margin-top: 10px;
}
.green-color
{
    color: #2F4A4E;
    font-size: 20px;
    
}