.contactus{
    background-image: linear-gradient(#068DAB,#fff);
    border-radius: 20px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    padding: 10px;
}

.cntct-2
{
    padding: 10px;
    background-color: #fff;
    border-radius: 20px;
}

.cntct-2 h2
{
   color: #068DAB;
}