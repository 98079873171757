body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Monteserrat";
  src: local("Monteserrat"),
    url(./assets/fonts/Montserrat-VariableFont_wght.ttf) format("truetype");
}



h1,h2,p
{
  font-family: "Monteserrat";
}
.black-color
{
  color: black;
  font-size: 14px;
  text-align: left;
}

.grey-color{
  color: #aaa;
  font-size: 14px;
  text-align: left;
}
p
{
  text-align: center;
}