

#home
{
    width: 100%;
    min-height: 100vh;
    
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
}

.layer2
{
    position: absolute;
    width: 100%;
    min-height: 100vh;
    background: #141F21CC;
    top: 0;

}
.d-block
{
    width: 100%;
    height: 100vh;
    object-fit: cover;
}

.main2 h1
{
    color: #fff;
    text-align: center;
    font-weight: lighter;
    font-size: 40px;
    
}
.main2 p
{
    
    color: #fff;
    text-align: center;
    font-size: 2vh;
    font-style: italic;

}
.logo
{
   max-width: 90%;
}

.wall
{
    position: absolute;
    top: 0;
    z-index: -1;
}

.wall
{
    width: 100%;
    height: 90vh;
    max-height: 90vw;
}

.quote
{
    position: relative;
    top:60vh;   
}
.quote2
{
    position: relative;
    top:45vh;   
}
.c-icon
{
    margin-top: 60vh;
}
.c-icon2
{
    margin-top: 45vh;
}