.foot
{
    margin-top: 250px;
    background: #2F4A4E;
    position: relative;
    padding: 100px;
}

.foot h3,h6
{
    color: #fff;
}

.foot h6
{
    font-style: italic;
}

    .grey-card
{
    top:-150px;
    position: absolute;
    background: #F7F3F2;
    padding: 50px;
    border-radius: 50px;
    width:75%;
    left:12.5%;
    
   
}
.grey-card2
{
    top:-200px;
    position: absolute;
    background: #F7F3F2;
    padding: 50px;
    border-radius: 50px;
    width:100%;
    left: 0;
   
}

.searchbar
{
    background-color: #fff;
    border : 1px solid  #D9D9D9;
    width:400px;
    max-width: 90%;
    height: 60px;
    border-radius: 20px;
}
.searchbar input
{
    border: none;
    background: none;
    height: 60px;
}
.searchbar input:focus
{
    
    outline: none;
    
}
.searchbar img
{
    margin-top: 25px;
}
a:hover
{
    cursor: pointer;
}