    .card1
    {
        background-color: #2F4A4E;
        position: relative;
        padding: 20px;
        border-bottom-right-radius: 20px;
        margin-top: 10px;
        height: 180px;
        color: #fff;
    }

    .card2
    {
        position: relative;
        background-color: #2F4A4E;;
        padding: 20px;
        border-bottom-left-radius: 20px;
        margin-top: 10px;
        height: 180px;
        color: #fff;
    }
    .card3
    {

        position: relative;
        margin-top: 40px;
        background-color: #2F4A4E;;
        padding: 20px;
       width:100%;
       height: 180px;
       color: #fff;
    
    }
    .fr
    {
     
        text-align: right;
    }
    .fl
    {
        float: left;
        text-align: left;
    }
    .sicon2
    {
        position: absolute;
        padding: 10px;
        height: auto;
        top:-40px;
        left: 0px;
        background-color: #F7F3F2;
        border-radius: 100%;
        border:5px solid #E9BD3A;
        width:70px;
    }
    .sicon
    {
        position: absolute;
        padding: 10px;
        height: auto;
        top:-40px;
        right: 0px;
        background-color: #F7F3F2;
        border-radius: 100%;
        border:5px solid #E9BD3A;
        width:70px;
        
    }
    .sicon3
    {
        position: absolute;
        padding: 10px;
        top:-40px;
        left:46%;
        background-color: #F7F3F2;
        border-radius: 100%;
        border:5px solid #E9BD3A;
        width:70px;
    }
    .sicon0
    {
        position: absolute;
        padding: 10px;
        top:-40px;
        left:42%;
        background-color: #F7F3F2;
        border-radius: 100%;
        border:5px solid #E9BD3A;
        width:70px;
    }
   